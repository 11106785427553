import { Student } from '../interfaces/students';
import axios from 'axios';

// Fetch Students interface
interface FetchStudents {
  students?: Student[];
  error?: any;
}
// Interface for json
interface Students {
  students?: Student[];
}

export async function fetchStudents(): Promise<FetchStudents> {
  try {
    const response = await axios.get<Students>(
      'https://api.hatchways.io/assessment/students'
    );
    const { students } = await response.data;
    // add tags
    if (students) {
      students.forEach((student) => (student.tags = []));
    }
    return { students };
  } catch (error) {
    return { error };
  }
}
