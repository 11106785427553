import React from 'react';
// Interfaces
import { Student } from 'interfaces/students';
// UI
import { styled } from '../ui/stiches.config';
// Icons
import { Minus } from '@geist-ui/react-icons';
import { Plus } from '@geist-ui/react-icons';
// Components
import { Box } from './Common';
import { Input } from './Common';

// Student Card Prop
interface StudentCardProp {
  student: Student;
  addTag: (tag: string, student: Student) => void;
}

export const StudentCard: React.FC<StudentCardProp> = ({ student, addTag }) => {
  // toggle state
  const [toggle, setToggle] = React.useState(false);

  // tag input
  const [value, setValue] = React.useState('');
  // handle tag input change
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  // handle tag enter
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (value) {
      addTag(value, student);
      setValue('');
    }
  };

  // calculate grade average
  const average =
    student.grades.reduce((prev, curr) => +prev + +curr, 0) /
    student.grades.length;

  return (
    <Card>
      <Avatar
        variant="medium"
        outlined
        src={student.pic}
        alt="Student Profile"
      />
      <CardContent>
        <CardHeading>
          <Typography variant="heading" uppercase>
            {student.firstName} {student.lastName}
          </Typography>
          <ToggleButton onClick={() => setToggle((prev) => !prev)}>
            {toggle ? <Minus size={50} /> : <Plus size={50} />}
          </ToggleButton>
        </CardHeading>
        <Info>
          <Typography variant="info">Email: {student.email}</Typography>
          <Typography variant="info">Company: {student.company}</Typography>
          <Typography variant="info">Skill: {student.skill}</Typography>
          <Typography variant="info">Average: {average}</Typography>
          {/* toggle grades */}
          {toggle && (
            <Box css={{ marginTop: '1rem' }}>
              {student.grades.map((grade, index) => (
                <Typography key={index} variant="info">
                  Test{index + 1}:{'      '} {grade}%
                </Typography>
              ))}
            </Box>
          )}
          {/* show tags */}
          {student.tags && (
            <Box css={{ display: 'flex', flexWrap: 'wrap', marginY: '10px' }}>
              {student.tags.map((tag, index) => (
                <Label key={index}>{tag}</Label>
              ))}
            </Box>
          )}
          {/* add tags */}
          <form onSubmit={handleSubmit}>
            <Input
              css={{ marginTop: '5px' }}
              placeholder="Add a tag"
              value={value}
              onChange={handleChange}
            />
          </form>
        </Info>
      </CardContent>
    </Card>
  );
};

// Card Container
const Card = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  padding: '1.2rem 0rem',
  borderBottom: '1px solid LightGray',
  overflow: 'hidden',
});

// Avatar Component
const Avatar = styled('img', {
  verticalAlign: 'middle',
  borderRadius: '50%',
  background: 'White',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1rem',
  // Variants
  variants: {
    variant: {
      large: {
        width: '150px',
        height: '150px',
      },
      medium: {
        width: '120px',
        height: '120px',
      },
      small: {
        width: '90px',
        height: '90px',
      },
    },
    outlined: {
      true: {
        boxShadow: '0 0 0 1px DarkGray',
      },
    },
  },

  defaultVariants: {
    variant: 'large',
  },
});

// Card Content Component
const CardContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  width: '100%',
});

// Card Heading
const CardHeading = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

// Toggle Grades Button
const ToggleButton = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  color: 'Gray',

  '&:hover': {
    color: 'Black',
    cursor: 'pointer',
  },
});

// Card Typography
const Typography = styled('p', {
  fontSize: '1rem',
  // Variants
  variants: {
    variant: {
      heading: {
        fontSize: '3rem',
        letterSpacing: '5px',
        fontWeight: 'bold',
      },
      info: {
        fontSize: '1.1rem',
        fontWeight: 'normal',
        letterSpacing: '2px',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
  },
});

// Students Info
const Info = styled('div', {
  margin: '0.8rem',
});

// Tag label
const Label = styled('div', {
  margin: '5px',
  backgroundColor: 'Gainsboro',
  borderRadius: '10px',
  padding: '10px',
});
