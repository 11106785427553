import { styled } from '../ui/stiches.config';

export const Box = styled('div', {});

export const Input = styled('input', {
  // reset
  border: 'none',

  height: '3rem',
  borderBottom: '2px solid LightGray',
  fontSize: '1rem',
  padding: '1rem',

  // focus state
  '&:focus': {
    outline: 'none',
    borderColor: 'Black',
  },
});
