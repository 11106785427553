import { createStitches, PropertyValue } from '@stitches/react';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  media: {
    bp1: '(min-width: 480px)',
  },
  utils: {
    marginY: (value: PropertyValue<'margin'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    marginX: (value: PropertyValue<'margin'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
  },
});
