import React from 'react';
import { Box } from './components/Common';
import { StudentCards } from './components/Cards';
// global styles
import { globalStyles } from './ui/global';

const App: React.FC = () => {
  // Apply global styles
  globalStyles();
  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <StudentCards />
    </Box>
  );
};

export default App;
