import React from 'react';
// UI
import { styled } from '../ui/stiches.config';
// Components
import { StudentCard } from './Card';
import { Input } from './Common';
// Custom Hook
import { useStudents } from '../hooks/useStudents';

export const StudentCards: React.FC = () => {
  // student api hook
  const { students, loading, error, addTag } = useStudents();
  // input filter state
  const [filter, setFilter] = React.useState({ name: '', tag: '' });
  // input filter change handler
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilter({ ...filter, [name]: value.toLowerCase() });
  };

  // Filter students using filter input(s)
  const filterStudents = () => {
    // student has tags and filter is set
    if (filter.tag && students.find((student) => student.tags.length)) {
      return students
        .filter((student) =>
          student.tags.find((tag) => tag.includes(filter.tag))
        )
        .filter(
          (student) =>
            student.firstName.toLowerCase().includes(filter.name) ||
            student.lastName.toLowerCase().includes(filter.name)
        );
    }
    return students.filter(
      (student) =>
        student.firstName.toLowerCase().includes(filter.name) ||
        student.lastName.toLowerCase().includes(filter.name)
    );
  };
  // loading state
  if (loading) {
    return <div>Loading...</div>;
  }
  // Network error or api error
  if (error) {
    return <div>Error...</div>;
  }

  return (
    <Cards>
      <Input
        type="text"
        name="name"
        placeholder="Search by name"
        onChange={handleChange}
        value={filter.name}
        css={{ width: '100%' }}
      />
      <Input
        type="text"
        name="tag"
        placeholder="Search by tag."
        onChange={handleChange}
        value={filter.tag}
        css={{ width: '100%' }}
      />
      <CardsContent>
        {filterStudents().map((student) => (
          <StudentCard key={student.id} student={student} addTag={addTag} />
        ))}
      </CardsContent>
    </Cards>
  );
};

const Cards = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  background: 'White',
  marginY: 'auto',
  borderRadius: '15px',
  width: '60rem',
  height: '50rem',
  overflow: 'hidden',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
});

const CardsContent = styled('div', {
  width: '60rem',
  height: '49rem',
  overflow: 'auto',
  marginY: 'auto',
});
