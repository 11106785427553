import { useState, useEffect } from 'react';
import { fetchStudents } from '../api/students';
import { Student } from 'interfaces/students';

export const useStudents = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const addTag = (tag: string, student: Student) => {
    student.tags.push(tag.toLowerCase());
    // get students index
    const index = students.findIndex(({ id }) => student.id === id);
    const alteredStudents = [...students];
    // replace student with updated tag
    alteredStudents[index] = student;
    setStudents(alteredStudents);
  };

  useEffect(() => {
    const getStudents = async () => {
      setLoading(true);
      const { students, error } = await fetchStudents();
      if (error) {
        setError(error);
        setLoading(false);
      }
      if (students) {
        setStudents(students);
        setLoading(false);
      }
    };
    getStudents();
  }, []);

  return { students, loading, error, addTag };
};
