import { globalCss } from './stiches.config';

// global styles and resets
export const globalStyles = globalCss({
  '*': {
    margin: 0,
    padding: '0',
    boxSizing: 'border-box',
    fontFamily: 'Raleway',
  },
  // default app background color
  body: {
    backgroundColor: 'Gainsboro',
  },
});
